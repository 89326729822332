import React, { Fragment, useEffect } from "react";

//TODO - dont render on preview
//TODO: - get ad client from site config

const AdSenseAd = ({ slot, format, layoutKey, layout, fullWidthResponsive, style = {} }) => {
	useEffect(() => {
		if (window) {
			(window.adsbygoogle = window.adsbygoogle || []).push({});
		}
	}, []);

	format = format || "fluid";
	style = {
		display: "block",
		minWidth: style.minWidth || (format === "fluid" ? "251px" : undefined),
		...style,
	};

	return <Fragment>
		<ins className="adsbygoogle"
			style={style}
			data-ad-format={format}
			data-ad-layout={layout}
			data-ad-layout-key={layoutKey}
			data-ad-client="ca-pub-2048419014371911"
			data-ad-slot={slot}
			data-full-width-responsive={fullWidthResponsive} />
	</Fragment>;
};

export default AdSenseAd;
